import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { modifsuccess,showapercu } from "./FactureSlice";
// import ModalApercu from './ModalApercu';
// import TotalFacture from './TotalFacture';

function Editprixfacture() {

    const id = useSelector(state => state.userData.id);
    const Facturedata = useSelector(state => state.facturesData);
    const currentfacture = Facturedata.currentFacture;
    // console.log(currentfacture)

    const [listProduits, setListProduits] = useState([]);
    // const [totalFacture,setTotalFacture] = useState('');

    let listAchats=useRef([]);

    
    const StockUpdate = (stock,event) => {
        let promo=0
        let prix = event.target.value;
        if(prix==="")
            {
                prix="0"
            }

        const id = event.target.id;
        const updatedListProduits = listProduits.map(product => {
            if (product._id === id) {
                // product.stock = qte;
                const _id=product._id;
                const designation=product.designation;
                // const stock=product.stock;
                const stock=product.stock;
                let prix_vente=prix;
                const prix_achat=product.prix_achat;
                const tva=product.tva;

                const valeurs={_id,designation,stock,prix_vente,promo,prix_achat,tva};
                // Vérifier si l'_id existe déjà dans le tableau
                const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                if (existingProductIndex !== -1) {
                    // Mettre à jour la valeur du stock
                    listAchats.current[existingProductIndex].prix_vente = prix_vente;
                  } else {
                    // Ajouter le nouveau produit au tableau
                    listAchats.current.push({...valeurs});
                  }
                  console.log(listAchats.current)
                return { listAchats };
            }
            return product;
        });
        // setTotalFacture(calculateSum(listAchats.current))
    };

    function ShowList() {
        if(listProduits.length > 0){
            let listFiltre=listProduits
        return listFiltre.map(Produit => {

            return(
                <tr key={Produit._id}>
                <td>{Produit.designation}</td>
                <td>{Produit.stock}</td>
                <td><input type="number" className="form-control-sm" id={Produit._id} defaultValue={Produit.prix_vente} onChange={(event) => StockUpdate(Produit.prix_vente, event)} placeholder="Prix vente"></input></td>
            </tr>                
            )
            
        });
        
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    
    }   


    const getListAchat=async () => {
        try {

        const apiUrl = process.env.REACT_APP_API_URL;
        const idfacture = currentfacture.map(facture => facture._id);
        const response = await axios.post(`${apiUrl}list/facture/${id}/${idfacture}`);

        const result = response.data.produits

        setListProduits(result);
        console.log(result)
        }
     catch (error) {
        console.error('Error:', error);    
    }
    }

    const GetFacture = async (idfacture) =>{
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}generer/facture/${id}/${idfacture}`,{
              responseType: 'blob',
            });
        
            // Créez un lien pour télécharger le PDF
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Facture.pdf`);
            document.body.appendChild(link);
            link.click();
        }
            catch (error) {
                console.error('Error generating invoice:', error);
              }
    }

    const Modifier = async () => {
        const idfacture = currentfacture.map(facture => facture._id);
        const produits = listAchats.current.map(produit => {
            const { _id, ...rest } = produit;
            return { id: _id, ...rest }; // on remplace _id par id
        });
    
        const apiUrl = process.env.REACT_APP_API_URL;
    
        const response = await axios.post(`${apiUrl}edit/prixfacture/${id}/${idfacture}`, {
            produits
        });
        console.log(response.data)
        if(response.data.status==="ok")
        {
            GetFacture(idfacture)
        }
    };
    useEffect(() =>{
        getListAchat();
        
      },[])

  return (
    <div className="container-lg align-items-center">
    <p className="h2 mt-4 text-center">Modifier prix facture</p>

    <p className='h4 mt-4 text-center'>Client  : {currentfacture["0"]?.client["0"]?.designation}</p>
    <p className='h4 mt-2 text-center'>Facture N°  : {currentfacture["0"]?.facture}</p>


    <div class="table-responsive mt-4">
<table className="table align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Désignation</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Prix de vente</th>
                </tr>
            </thead>
            <tbody>
            <ShowList/>
            </tbody>
            </table>
            </div>

            <div className="d-flex justify-content-end mb-3">
            {/* <TotalFacture total={totalFacture}/> */}
            <button type="button" className="btn btn-success ms-3" onClick={Modifier}>Enregistrer & Imprimer</button>
      </div>

    </div>
  )
}

export default Editprixfacture