import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal} from "./FactureSlice";
import axios from 'axios';
import Select from 'react-select';

export default function Impressiongroupe() {
    const dispatch= useDispatch();
    const id = useSelector(state => state.userData.id);
    const Facturedata = useSelector(state => state.facturesData);
    const modalShow = Facturedata.modalShow;
    const [etat,setEtat] =useState('')
    const [listClient,setListClient] =useState([])
    const [loading,setLoading] =useState(false)

    const ClientField= useRef('')
    const DatedebutField= useRef('')
    const DateFinField = useRef('')

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [selectedDate2, setSelectedDate2] = useState(todayDate);

    const formatDateToIso = (dateString,type) => {
        const formattedDate = new Date(`${dateString}T00:00:00Z`);
        if(type==="fin"){
            formattedDate.setHours(23, 59, 59, 999);
        }
        return formattedDate.toISOString();
      };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    
      const handleDateChange2 = (event) => {
        setSelectedDate2(event.target.value);
      };  

    const Close = () => dispatch(showmodal(false));

    const GetClients=async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}clients/${id}`);
        const listeClient = [{
            value : "Tous",
            label : "Tous",
        }]
        const clientData = response.data.map(client => ({
            value: client._id,
            label: client.designation,
          }));
          listeClient.push(...clientData)
          setListClient(listeClient)
        }
     catch (error) {
        console.error('Error:', error);
    }
    }

    const Telecharger = async () =>{
                setEtat('')
        setLoading(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const debut = DatedebutField.current.value;
        const fin = DateFinField.current.value;
        const dataclient=ClientField.current.getValue()
        let idclient
        if(dataclient.length>0){
            const { value } = dataclient[0];
            idclient={
                "id" : value,
            }
        }
        else {
            idclient={
                "id" : 0,
            }    
        }

        const debutIso=formatDateToIso(debut,"debut")
        const finIso=formatDateToIso(fin,"fin")

        try {
            const response = await axios.get(`${apiUrl}telecharger/factures/${id}/${debutIso}/${finIso}/${idclient["id"]}`,{
              responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/zip'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Factures du ${debut} au ${fin}.zip`);
            document.body.appendChild(link);
            link.click();
        }
        catch (error) {
            setEtat('erreur');
        }       
        setLoading(false)

    }

    useEffect(() =>{
        GetClients();
        setSelectedDate(todayDate);
        setSelectedDate2(todayDate);
      },[modalShow])
    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Impression groupée</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }
                    <label className="form-label" for="client">Client</label>
                    <Select options={listClient} placeholder="Rechercher un client" ref={ClientField} />
                    <label className="form-label" for="debut">Date debut</label>
                    <input autoComplete="off" type="date" className="form-control" id="debut" value={selectedDate} onChange={handleDateChange}  ref={DatedebutField}></input>
                    
                    <label className="form-label" for="fin">Date fin</label>
                    <input autoComplete="off" type="date" className="form-control" id="fin" value={selectedDate2} onChange={handleDateChange2}  ref={DateFinField}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Traitement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Telecharger}>Télécharger</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Fermer</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
