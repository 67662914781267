import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ajoutsuccess,idachat,modifsuccess,showapercu,showetat } from "./AchatsSlice";
import Listfournisseurs from './Listfournisseurs';
import Apercu from './Apercu';
import EtatAchats from './EtatAchat';

export default function Achats() {

  const achatsData = useSelector(state => state.achatsData);
  const ajoutSuccess = achatsData.ajoutsuccess;
  const modifSuccess = achatsData.modifsuccess;
  const [Chargement, setChargement] = useState(false);
  const [listachats, setListachats] = useState([]);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const id = useSelector(state => state.userData.id);
  const [rechercheFournisseur,setrechercheFournisseur] = useState('tout');

  const [dateDebutInput,setDateDebutInput] = useState('');
  const [dateFinInput,setDateFinInput] = useState('');

  const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  const listAchats=useRef([]);

  const dateDebutField=useRef('');
  const dateFinField=useRef('');

  const modifier = (id) => {
    const filtreachat = listachats.filter(list => list._id === id);
    dispatch(idachat(filtreachat));
    navigate('/home/editachat');
  }

  const suppression = async (idachat)=> {

    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}delete/achat/${id}/${idachat}`);
        if(response.data.status==="ok")
            {
                const newlist=listachats.filter(achat => achat._id !== idachat);
                setListachats(newlist);
                setRemoveSuccess(true);
                HideSuccessMsg();
            }
    } catch (error) {
        console.log(error)
    }
}

  const supprimer = (id) => {
    let result = window.confirm(`Supprimer cet achat ?`);
    if (result === true) {
        suppression(id);
    }
}

const ShowApercu=async (idachat) => {
  try {
  const apiUrl = process.env.REACT_APP_API_URL;
  const response = await axios.post(`${apiUrl}list/achat/${id}/${idachat}`);
  listAchats.current = response.data.produits
  dispatch(showapercu(true))
  }
  catch (error) {
      console.error('Error:', error);    
  }
}

const formatDateToEnglish = (dateString) => {
  const [date] = dateString.split('T');
  return `${date}`;
};

  function Achats(){
    let listFiltre=listachats;
    const debut = dateDebutField.current.value
    const fin = dateFinField.current.value

    if (rechercheFournisseur !=="tout"){
      listFiltre = listFiltre.filter(achat => 
        achat.fournisseur.some(fournisseur => 
          fournisseur.id===rechercheFournisseur) 
          )
      ;               
  }

  if(debut !=="")
    {
        listFiltre = listFiltre.filter(achat => formatDateToEnglish(achat.dateArrivage)>=debut)      
    }

    if(fin !=="")
      {
          listFiltre = listFiltre.filter(achat => formatDateToEnglish(achat.dateArrivage)<=fin)      
      }
    return listFiltre.map((achat,index) => {
        let StyleColor = "#99FBA9"
        if(achat.etat==="Non Réglée"){
          StyleColor = "#FBD399"
        }
        return(
              <tr key={achat._id}>
                {achat.fournisseur.map(fournisseur => (
                <td>{fournisseur.designation}</td>
                ))}
                <td>{achat.facture}</td>
                <td>{achat.montant}</td>
                <td style={{color:StyleColor}}>{achat.etat}</td>
                <td>{achat.dateArrivage!=="" ? formatDateToFrench(achat.dateArrivage) : ''}</td>
                <td>{achat.dateReglement!=="" ? formatDateToFrench(achat.dateReglement) : ''}</td>
                <td>
                <button type="button" className="btn btn-primary me-4" onClick={() =>ShowApercu(achat._id)}>Aperçu</button>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(achat._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(achat._id)}>Supprimer</button>
                </td>
              </tr>
        );
    });
}

const GetAchats=async () => {
  setChargement(true)
    try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await axios.post(`${apiUrl}achats/${id}`);
    setListachats(response.data);
    }
  catch (error) {
    console.error('Error:', error);
  }
  setChargement(false)
}

  const fournisseurField=useRef();
  const navigate = useNavigate();
  const dispatch= useDispatch();

  const Recherche=(event) =>{
    const idfournisseur=fournisseurField.current.value

    const debut= dateDebutField.current.value
    const fin=dateFinField.current.value

     if(idfournisseur!==rechercheFournisseur){
      setrechercheFournisseur(idfournisseur)
}

if(debut!==dateDebutInput){
  setDateDebutInput(debut)
  }

  if(fin!==dateFinInput){
      setDateFinInput(fin)
      }

}
  const Ajouter=() => {
  navigate('/home/nouvelachat');
  }

  const HideSuccessMsg = () => {
    setTimeout(() => {
        dispatch(ajoutsuccess(false));
        dispatch(modifsuccess(false));
        setRemoveSuccess(false);

    }, 5000);
}
const EtatAchat = () => dispatch(showetat(true));
useEffect(() =>{
  HideSuccessMsg();
  GetAchats();
  },[]);
  return (
    <>
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Achats</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

    <div className="d-flex justify-content-end mb-3">
        
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Nouvel achat
    </button>
    <button className="btn btn-success ms-2" onClick={EtatAchat}>
          <i className="fas fa-user-plus"></i> Etat des Achats
        </button>
  </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Achat ajouté avec succès</strong>
        </div>                 
            :
            ''                    
        }

{modifSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Achat modifié avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Achat supprimé avec succès</strong>
        </div>                 
            :
            ''                    
        }

<div className="d-flex justify-content-start align-items-center mb-3 w-50" >
  
  <select className="form-select" id="fournisseur" value={rechercheFournisseur} ref={fournisseurField} onChange={Recherche}>
        <option value="tout">Fournisseur</option>
            <Listfournisseurs/>
            </select>    

            <label className="form-label ms-2" for="debut">Debut</label>
      <input autoComplete="off" type="date" className="form-control ms-2" id="debut" ref={dateDebutField} onChange={Recherche}></input>

      <label className="form-label ms-2" for="debut">Fin</label>
      <input autoComplete="off" type="date" className="form-control ms-2" id="fin" ref={dateFinField} onChange={Recherche}></input>


    </div>
    <div class="table-responsive">
  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Fournisseur</th>
                <th scope="col">N° facture</th>
                <th scope="col">Montant</th>
                <th scope="col">Etat</th>
                <th scope="col">Date d'arrivage</th>
                <th scope="col">Date réglement</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <Achats/>
        </tbody>
    </table>
    </div>
    <Apercu Achats={listAchats} />
    <EtatAchats/>
</section>
    </div>
</>
    
  )
}
