import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,getList,ajout,modalModifShow,modif,current,remove } from "./ClientsSlice";

import ModalAjouter from './ModalAjouter';
import ModalModifer from './ModalModifier';

export default function Clients() {
    const ajoutSuccess=useSelector(state => state.clientsData.ajoutOk);
    const [Chargement, setChargement] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [rechercheInput,setRchercheInput] = useState('');
    const id = useSelector(state => state.userData.id);
    const listClients=useSelector(state => state.clientsData.list);
    const modifSuccess=useSelector(state => state.clientsData.modifOk);


    const dispatch= useDispatch();
    const rechercheField=useRef('');

    const Ajouter = () => dispatch(showmodal(true));

    const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        if(recherche!==rechercheInput){
        setRchercheInput(recherche)
         }
    }

    const HideSuccessMsg = () => {
        setTimeout(() => {
            dispatch(ajout(false));
            dispatch(modif(false));
            setRemoveSuccess(false);
        }, 5000);
    }

    const suppression = async (idclient)=> {

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/client/${id}/${idclient}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idclient));
                    setRemoveSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
    }

    const supprimer = (idclient) => {
        let valeur = listClients.find(client => client._id === idclient);
        valeur=valeur.designation
        let result = window.confirm(`Supprimer le client ${valeur} ?`);
        if (result === true) {
            suppression(idclient);
        }
    }

    const modifier = (id) => {
        dispatch(modalModifShow(true));
        const valeur = listClients.find(client => client._id === id);
        dispatch(current(valeur));
    };

    function ShowList() {
        HideSuccessMsg();
        if(listClients.length > 0){
            let listFiltre=listClients
            if(rechercheInput.length > 2) {
                listFiltre = listFiltre.filter(client => client.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
            }
        return listFiltre.map(Client => {
            return(
                <tr key={Client._id}>
                <td style={{ maxWidth: "200px" }}>{Client.designation}</td>
                <td>{Client.contact}</td>
                <td>{Client.tel}</td>
                <td>{Client.ice}</td>
                <td style={{ maxWidth: "200px" }}>{Client.ville}</td>
                <td>{Client.rib}</td>
                <td>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(Client._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(Client._id)}>Supprimer</button>
                </td>
            </tr>                
            )
        })
    }
    else{
        return <tr>
            <td colSpan={7} align='center'>Pas de données</td>
        </tr>;
    }
    }

    const getClients=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}clients/${id}`);
        dispatch(getList(response.data));
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }
    useEffect(() =>{
        getClients();
        dispatch(showmodal(false));
        },[]);

return (
    <>
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Clients</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

    <div className="d-flex justify-content-end mb-3">
        
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Ajouter un client
    </button>
  </div>

  <div className="d-flex justify-content-start mb-3 w-50" >
        
  <input autoComplete="off" type="text" className="form-control w-50 " id="designation"  placeholder="Recherche client" ref={rechercheField} onKeyUp={Recherche} ></input>

    </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Client ajouté avec succès</strong>
        </div>                 
            :
            ''                    
        }

{modifSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Client modifié avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Client supprimé avec succès</strong>
        </div>                 
            :
            ''                    
        }
        <div class="table-responsive">
  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Nom client</th>
                <th scope="col">CIN</th>
                <th scope="col">Tel</th>
                <th scope="col">ICE</th>
                <th scope="col">Adresse</th>
                <th scope="col">RIB</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
</section>
    </div>
    <ModalAjouter />
    <ModalModifer />
</>
    
  )
}