import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,ajout,addOne } from "./AvoirsSlice";
import axios from 'axios';
import Select from 'react-select';

export default function ModalAjouter(NumeroAvoir) {
    const apiUrl = process.env.REACT_APP_API_URL;

    const AvoirData = useSelector(state => state.avoirsData);
    const modalShow = AvoirData.modalShow;
    const listClients = AvoirData.listClients;
    const id = useSelector(state => state.userData.id);

    const [loading,setLoading] =useState(false)
    const [etat,setEtat] =useState('')

    const clientField=useRef('')
    const montantField=useRef('')
    const remarqueField=useRef('')
    const dateField=useRef('')

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const [selectedDate, setSelectedDate] = useState(todayDate);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };

    const ViderChamps = () => {
        montantField.current.value = '';
        remarqueField.current.value = '';

    }

    const formatDateToIso = (dateString) => {
        const formattedDate = new Date(`${dateString}T00:00:00Z`);
        formattedDate.setHours(23, 59, 59, 999);
        return formattedDate.toISOString();
        };

    const Ajouter = async ()=> {
        const dataclient=clientField.current.getValue()
        const { value, label } = dataclient[0];

        const client=[{
            "id" : value,
            "designation" :label,
        }]

        let montant=montantField.current.value
        montant=montant.replace(",", ".");

        const remarque = remarqueField.current.value.trim()
        const date = formatDateToIso(dateField.current.value)
        const numero =NumeroAvoir.NumeroAvoir

        const etat="Enregistré"
        setEtat('')
        setLoading(true)
        try {
            const response = await axios.post(`${apiUrl}new/avoir/${id}`, {
                client,
                numero,
                montant,
                remarque,
                etat,
                date,
            });
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const data={_id,client,numero,montant,remarque,etat,date};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    ViderChamps();
                }
            else{
                setEtat('erreur');
            }
        } catch (error) {
            setEtat('erreur');
        }
        setLoading(false)
    }

    const dispatch= useDispatch();
    const Close = () => {
        dispatch(showmodal(false));
        ViderChamps();
        setEtat('');
        
    };

    useEffect(() =>{
        ViderChamps();
        setEtat('');
      },[]);

    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter un avoir</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }                    
                    <label className="form-label" for="categorie">Client</label>
                    <Select options={listClients} placeholder="Rechercher un client" ref={clientField} />
                    <label className="form-label" for="montant">Montant</label>
                    <input autoComplete="off" type="number" className="form-control" lang="en" id="montant" ref={montantField}></input>

                    <label className="form-label" for="remarque">Remarque</label>
                    <input autoComplete="off" type="text" className="form-control" lang="en" id="remarque" ref={remarqueField}></input>

                    <label className="form-label" for="date">Date d'avoir</label>
                    <input autoComplete="off" type="date" className="form-control" id="date" ref={dateField} value={selectedDate} onChange={handleDateChange}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    }


