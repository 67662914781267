import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,ajout,addOne } from "./FournisseursSlice";
import axios from 'axios';

export default function ModalAjouter() {

    const fournisseursData = useSelector(state => state.fournisseursData);
    const modalShow = fournisseursData.modalShow;
    const id = useSelector(state => state.userData.id);

    const [loading,setLoading] =useState(false)
    const [etat,setEtat] =useState('')

    const designationField=useRef('')
    const cinField=useRef('')
    const compteField=useRef('')
    const telField=useRef('')
    const adresseField=useRef('')
    const emailField=useRef('')

    const ViderChamps = () => {
        designationField.current.value = '';
        cinField.current.value = '';
        compteField.current.value = '';
        telField.current.value = '';
        adresseField.current.value = '';
        emailField.current.value = '';
    }

    const Ajouter = async ()=> {
        const designation=designationField.current.value.trim()
        const cin=cinField.current.value.trim()
        const compte=compteField.current.value.trim()
        const tel = telField.current.value.trim()
        const adresse = adresseField.current.value.trim()
        const email = emailField.current.value.trim()
        if(designation==="")
            {
                alert("Désignation vide");
                return;
            }
            setEtat('')
            setLoading(true)
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}new/fournisseur/${id}`, {
                designation,
                cin,
                compte,
                tel,
                adresse,
                email,
            });
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const data={_id,designation,compte,tel,adresse,email,cin};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    ViderChamps();
                }
            else{
                setEtat('erreur');
            }
        } catch (error) {
            setEtat('erreur');
        }
        setLoading(false)
    }

    const dispatch= useDispatch();
    const Close = () => {
        dispatch(showmodal(false));
        ViderChamps();
        setEtat('');
        
    };

    useEffect(() =>{
        ViderChamps();
        setEtat('');
      },[]);

    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter un fournisseur</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }
                    <label className="form-label" for="designation">Nom fournisseur</label>
                    <input autoComplete="off" type="text" className="form-control" id="designation" placeholder="Nom du fournisseur" ref={designationField}></input>

                    <label className="form-label mt-4" for="compte">CIN</label>
                    <input autoComplete="off" type="text" className="form-control" id="cin" placeholder="N° CIN" ref={cinField}></input>

                    <label className="form-label mt-4" for="tel">Tel.</label>
                    <input autoComplete="off" type="text" className="form-control" id="tel" placeholder="Tel du fournisseur" ref={telField}></input>

                    <label className="form-label mt-4" for="tel">Adresse</label>
                    <input autoComplete="off" type="text" className="form-control" id="adresse" placeholder="Adresse du fournisseur" ref={adresseField}></input>

                    <label className="form-label mt-4" for="email">E-Mail</label>
                    <input autoComplete="off" type="text" className="form-control" id="email" placeholder="E-mail du fournisseur" ref={emailField}></input>

                    <label className="form-label mt-4" for="compte">N° Compte</label>
                    <input autoComplete="off" type="text" className="form-control" id="compte" placeholder="N° de Compte du fournisseur" ref={compteField}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    }


