import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    apercuShow : false,
    ajoutsuccess : false,
    modifsuccess : false,
    currentAchat : [],
    etatShow : false,
}

const Achats = createSlice({
    name : 'AchatsData',
    initialState,
    reducers :{
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },
        modifsuccess:(state,action) =>{
            state.modifsuccess=action.payload
        },
        idachat:(state,action) =>{
            state.currentAchat=action.payload
        },
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        },
        showetat:(state,action) =>{
            state.etatShow=action.payload
        },
    }
})

export const {ajoutsuccess,idachat,modifsuccess,showapercu,showetat} = Achats.actions
export default Achats.reducer;