import React, { useEffect } from 'react'

function TotalFacture({total}) {

    useEffect(() => {
        console.log("Re-render triggered, new total:", total);
      }, [total]); // Se déclenche à chaque changement de `total`

  return (
    <label style={{ fontSize: "25px", fontWeight:'Bold'}}>Total : {total} Dhs</label>
  )
}

export default TotalFacture