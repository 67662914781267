import {useState,useEffect,useRef} from 'react';
import { showmodal,getList,ajout,modalModifShow,modif,current,remove,listclient } from "./ChequesSlice";

import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import ModalAjouter from './ModalAjouter';
import ModalModifier from './ModalModifier';
export default function Cheques() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const userData = useSelector(state => state.userData);
    const id = userData.id;
    const typeUser = userData.type;
    const chequeData = useSelector(state => state.chequesData);
    const modifSuccess=chequeData.modifOk;
    const ajoutSuccess=chequeData.ajoutOk;
    const listCheque = chequeData.list;
    const dispatch= useDispatch();
    const [Chargement, setChargement] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [rechercheInput,setRchercheInput] = useState('');
    const [rechercheEtat,setRchercheEtat] = useState('tout');
    const [idCurrentclient,SetidCurrentclient] = useState('');

    const etatField=useRef('')
    const rechercheField = useRef('')

    const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      };


      const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        const etat=etatField.current.value
        if(recherche!==rechercheInput){
            setRchercheInput(recherche)
             }
             if(etat!==rechercheEtat){
                setRchercheEtat(etat)
            }
    }

    const Ajouter = () => dispatch(showmodal(true));

    const getCheques=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}cheques/${id}`);
        const today = new Date();
        const updatedData = response.data.map(item => {
            const itemDate = new Date(item.date);
            const itemEtat = item.etat;
            if (itemDate <= today && itemEtat==="Enregistré") {
                return { ...item, etat: "En attente d'encaissement" };
            }
            return item;
        });
        dispatch(getList(updatedData));
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }
    const GetClients=async () => {
        try {
        const response = await axios.post(`${apiUrl}clients/${id}`);

        const clientsData = response.data.map(client => ({
            value: client._id,
            label: client.designation,
          }));
          dispatch(listclient(clientsData));
        }
     catch (error) {
        console.error('Error:', error);
    }
    }

    const HideSuccessMsg = () => {
        setTimeout(() => {
            dispatch(ajout(false));
            dispatch(modif(false));
            setRemoveSuccess(false);
        }, 5000);
    }

    const modifier = (id) => {
        dispatch(modalModifShow(true));
        const valeur = listCheque.find(cheque => cheque._id === id);
        const idclient = valeur.client.id
        const designation = valeur.client.designation
        const currentOption ={value :idclient,label : designation}
        SetidCurrentclient({currentOption});
        dispatch(current(valeur));
    };

    function ShowList() {
        HideSuccessMsg();
        if(listCheque.length > 0){
        let listFiltre=listCheque;
        if(rechercheInput.length > 2) {
            listFiltre = listFiltre.filter(cheque => cheque.client.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
        }

        if (rechercheEtat !=="tout"){
            listFiltre = listFiltre.filter(cheque => cheque.etat===rechercheEtat) ;               
        }
        
        return listFiltre.map(cheque => {
            let StyleColor = "#99FBA9"
            if(cheque.etat==="Enregistré"){
              StyleColor = "#FBD399"
            }
            if(cheque.etat==="En attente d'encaissement"){
                StyleColor = "#F2CD17"
              }
            return(
                <tr key={cheque._id}>
                <td>{cheque.numero}</td>
                <td>{cheque.client.designation}</td>
                <td>{cheque.montant}</td>
                <td>{cheque.remarque}</td>
                <td style={{color:StyleColor}}>{cheque.etat}</td>
                <td>{cheque.date!=="" ? formatDateToFrench(cheque.date) : ''}</td>
                {typeUser==="admin" ?
                <td>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(cheque._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(cheque._id)}>Supprimer</button>
                </td>
                :
                ''
                }
            </tr>                
            )
        })
    }
    else{
        return <tr>
            <td colSpan={7} align='center'>Pas de données</td>
        </tr>;
    }
    }

    const suppression = async (idcheque)=> {
        setChargement(true)

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/cheque/${id}/${idcheque}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idcheque));
                    setRemoveSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
        setChargement(false)
    }
    

    const supprimer = (idcheque) => {

        let result = window.confirm(`Supprimer ce chèque ?`);
        if (result === true) {
            suppression(idcheque);
        }
    }

    useEffect(() =>{
        getCheques();
        GetClients();
      },[]);

  return (
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Chèques</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

    <div className="d-flex justify-content-end mb-3">
        
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Ajouter un chèque
    </button>
  </div>

  <div className="d-flex justify-content-start mb-3 w-50" >
        
  <input autoComplete="off" type="text" className="form-control w-50 " id="designation"  placeholder="Recherche client" ref={rechercheField} onKeyUp={Recherche} ></input>

  <select className="form-select form-select-sm w-25 ms-2" id="categorie" ref={etatField} onChange={Recherche}>
            <option value="tout">Etat</option>
            <option value="Enregistré">Enregistré</option>
            <option value="En attente d'encaissement">En attente d'encaissement</option>
            <option value="Encaissé">Encaissé</option>

</select>
    </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Chèque ajouté avec succès</strong>
        </div>                 
            :
            ''                    
        }

{modifSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Chèque modifié avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Chèque supprimé avec succès</strong>
        </div>                 
            :
            ''                    
        }
    <div class="table-responsive">
  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Numéro</th>
                <th scope="col">Client</th>
                <th scope="col">Montant</th>
                <th scope="col">Remarque</th>
                <th scope="col">Etat</th>
                <th scope="col">Date</th>
                {typeUser==="admin" ?
                <th scope="col">Action</th>
                :
                ''  
                }
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
</section>
<ModalAjouter/>
<ModalModifier CurrentIdClient={idCurrentclient}/>
    </div>  )
}
