import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,ajout,addOne } from "./ProduitsSlice";
import axios from 'axios';
import Catlist from './Catlist';
import Fournisseurs from './Fournisseur';

export default function ModalAjouter() {

    const ProduitsData = useSelector(state => state.produitsData);
    const modalShow = ProduitsData.modalShow;
    const id = useSelector(state => state.userData.id);

    const [loading,setLoading] =useState(false)
    const [etat,setEtat] =useState('')

    const designationField=useRef('')
    const catField=useRef('')
    const fournissField=useRef('')
    const prixvField=useRef('')
    const prixaField=useRef('')
    const tvaField=useRef('')

    const ViderChamps = () => {
        designationField.current.value = '';
        prixvField.current.value = '';
        prixaField.current.value = '';
        tvaField.current.value = '';
    }

    const Ajouter = async ()=> {
        const designation=designationField.current.value.trim()
        const idcategorie=catField.current.value
        let categorie = catField.current.options[catField.current.selectedIndex].text
        categorie=[{
            "id" : idcategorie,
            "designation" :categorie,
        }]
        const idfournisseur=fournissField.current.value
        let fournisseur = fournissField.current.options[fournissField.current.selectedIndex].text
        fournisseur=[{
            "id" : idfournisseur,
            "designation" :fournisseur,
        }]
        let prix_vente=prixvField.current.value.trim()
        prix_vente=prix_vente.replace(",", ".");

        let prix_achat=prixaField.current.value.trim()
        prix_achat=prix_achat.replace(",", ".");

        let tva=tvaField.current.value.trim()
        tva=tva.replace(",", ".");
        const stock = 0;

        if(designation==="")
            {
                alert("Désignation vide");
                return;
            }
            setEtat('')
            setLoading(true)
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}new/produit/${id}`, {
                designation,
                fournisseur,
                categorie,
                prix_vente,
                prix_achat,
                tva,
                stock,
            });
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const data={_id,designation,fournisseur,categorie,prix_vente,prix_achat,tva};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    ViderChamps();
                }
            else if(response.data.status==="exist")
             {
                alert("Produit déja existant");
            }
            else{
                setEtat('erreur');
            }
        } catch (error) {
            setEtat('erreur');
        }
        setLoading(false)
    }

    const dispatch= useDispatch();
    const Close = () => {
        dispatch(showmodal(false));
        ViderChamps();
        setEtat('');
        
    };

    useEffect(() =>{
        ViderChamps();
        setEtat('');
      },[]);

    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter un produit</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }
                    <label className="form-label" for="designation">Désignation</label>
                    <input autoComplete="off" type="text" className="form-control" id="designation" placeholder="Désignation" ref={designationField}></input>

                    <label className="form-label" for="fournisseur">Fournisseur</label>
                    <select className="form-select form-select-sm" id="fournisseur" ref={fournissField}>
                    <Fournisseurs/>
                    </select>

                    <label className="form-label" for="categorie">Catégorie</label>
                    <select className="form-select form-select-sm" id="categorie" ref={catField}>
                    <Catlist/>
                    </select>

                    <label className="form-label" for="prixa">Prix d'achat</label>
                    <input autoComplete="off" type="number" className="form-control" lang="en" id="prixa" placeholder="Prix d'achat" ref={prixaField}></input>

                    <label className="form-label" for="prixv">Prix de vente</label>
                    <input autoComplete="off" type="number" className="form-control" lang="en" id="prixv" placeholder="Prix de vente" ref={prixvField}></input>

                    <label className="form-label" for="tva">tva</label>
                    <input autoComplete="off" type="number" className="form-control" lang="en" id="tva" placeholder="TVA (%)" ref={tvaField}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    }


