import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,ajout,addOne} from "./ChargesSlice";
import axios from 'axios';
import ListTypeCharges from './ListTypeCharges';
export default function ModalAjouter() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const dispatch= useDispatch();
    const id = useSelector(state => state.userData.id);

    const Chargedata = useSelector(state => state.chargesData);
    const modalShow = Chargedata.modalShow;
    const [etat,setEtat] =useState('')
    const [loading,setLoading] =useState(false)

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [selecteFrequence, setselecteFrequence] = useState('Ponctuelle');

    const catField = useRef('');
    const frequenceField = useRef('');
    const montantField = useRef('');
    const dateField = useRef(selectedDate);
    const designationField = useRef('');

    const ViderChamps = () => {
        montantField.current.value = '';
        designationField.current.value = '';
    }

    const formatDateToIso = (dateString) => {
        const formattedDate = new Date(`${dateString}T00:00:00Z`);
        formattedDate.setHours(23, 59, 59, 999);
        return formattedDate.toISOString();
      };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };

      const handleFrequenceChange = (event) => {
        setselecteFrequence(event.target.value);
      };

      const Close = () => dispatch(showmodal(false));

      const Ajouter = async ()=> {
        let idcharge =""
        const categorie = catField.current.value
        const frequence = frequenceField.current.value
        const designation=designationField.current.value.trim()
        let date=new Date()
        date.setHours(23, 59, 59, 999);
        date=date.toISOString();
        date=date.toString()
        if(frequence ==="Ponctuelle"){
            date=formatDateToIso(dateField.current.value)
        }
        let montant=montantField.current.value
        montant=montant.replace(",", ".")
        setEtat('')
        setLoading(true)
        try {

            if(frequence ==="Perpétuelle"){
                const response1 = await axios.post(`${apiUrl}new/chargePerpetuelle/${id}`,{
                    categorie,
                    montant,
                    designation,
                });
                idcharge=response1.data.id    
            }

            const response = await axios.post(`${apiUrl}new/charge/${id}`, {
                idcharge,
                categorie,
                frequence,
                montant,
                designation,
                date,
            });
            if(response.data.status==="ok")
            {

                    const _id=response.data.id;
                    const data={_id,idcharge,categorie,frequence,montant,designation,date};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    ViderChamps();
                }
                else{
                    setEtat('erreur');
                }
            } catch (error) {
                setEtat('erreur');
            }
            setLoading(false)
    }

      useEffect(() =>{
        setSelectedDate(todayDate);
        ViderChamps();
        setEtat('');

      },[])

      return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter une charge</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }

                    <label className="form-label" for="categorie">Catégorie</label>
                    <select className="form-select form-select" id="categorie" ref={catField} >
                  <ListTypeCharges/>
                    </select>
                    
                    <label className="form-label mt-4" for="categorie">Fréquence</label>
                    <select className="form-select form-select" id="categorie" ref={frequenceField} onChange={handleFrequenceChange}>
                  <option value="Ponctuelle">Ponctuelle</option>
                  <option value="Perpétuelle">Perpétuelle</option>
                    </select>
                    
                    <label className="form-label mt-3" for="montant">Montant</label>
                    <input autoComplete="off" type="number" className="form-control" id="contact" ref={montantField}></input>

                    <label className="form-label mt-3" for="designation">Désignation</label>
                    <input autoComplete="off" type="text" className="form-control" id="designation" ref={designationField}></input>
                    
                     {selecteFrequence ==="Ponctuelle" ?
                     <>
                    <label className="form-label mt-3" for="date">Date</label>
                    <input autoComplete="off" type="date" className="form-control" id="date" ref={dateField} value={selectedDate} onChange={handleDateChange}></input>
                    </>
                    :
                    ""
                     }
                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
