import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './user/login';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';
import Home from './home';
import Fournisseurs from './fournisseurs/Fournisseurs';
import Categories from './categories/Categories';
import Produits from './produits/Produits';
import Achats from './achats/Achats';
import Nouvelachat from './achats/Nouvelachat';
import EditAchats from './achats/EditAchat';
import Factures from './factures/Factures';
import Clients from './clients/Clients';
import Nouvellefacture from './factures/Nouvellefacture';
import Avoirs from './avoirs/Avoirs';
import Editfacture from './factures/Editfacture';
import Promotions from './promotions/Promotions';
import Stock from './stock/Stock';
import ProduitsStock from './stock/ProduitsStock';
import RetourStock from './stock/RetourStock';
import NouvelRetour from './stock/NouvelRetour';
import Echantillon from './stock/Echantillon';
import NouvelEchantillon from './stock/NouvelEchantillon';
import Utilisateurs from './users/Utilisateurs';
import Parametres from './parametres/Parametres';
import Secure from './user/Secure';
import Setup2fa from './user/Setup2fa';
import Cheques from './cheques/Cheques';
import Charges from './charges/Charges';
import Stats from './home/Stats';
import Dashboard from './stats/Dashboard';
import Statistiques from './stats/Statistiques';
import Benefice from './stats/Benefice';
import Editprixfacture from './factures/Editprixfacture';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter(
  createRoutesFromElements(
  <Route>
  <Route index element={<Login />} />
    <Route path="home" element={<Home />} >
        <Route index element={<Stats />} />
        <Route path="produits" element={<Produits />} />
        <Route path="categories" element={<Categories />} />
        <Route path="fournisseurs" element={<Fournisseurs />} />
        <Route path="achats" element={<Achats />} />
        <Route path="nouvelachat" element={<Nouvelachat />} />
        <Route path="editachat" element={<EditAchats />} />
        <Route path="factures" element={<Factures />} />
        <Route path="nouvellefacture" element={<Nouvellefacture />} />
        <Route path="editfacture" element={<Editfacture />} />
        <Route path="editprixfacture" element={<Editprixfacture />} />
        <Route path="clients" element={<Clients />} />
        <Route path="avoirs" element={<Avoirs />} />
        <Route path="cheques" element={<Cheques />} />
        <Route path="charges" element={<Charges />} />
        <Route path="promos" element={<Promotions />} />
        <Route path="stock" element={<Stock />} >
        <Route index element={<ProduitsStock />} />
        <Route path="produits" element={<ProduitsStock />} />
        <Route path="retourstock" element={<RetourStock />} />
        <Route path="nouvelretour" element={<NouvelRetour />} />
        <Route path="echantillon" element={<Echantillon />} />
        <Route path="nouvelechantillon" element={<NouvelEchantillon />} />
        </Route>

        <Route path="statistiques" element={<Statistiques />} >
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="benefice" element={<Benefice />} />

        </Route>

        <Route path="utilisateurs" element={<Utilisateurs />} />
        <Route path="parametres" element={<Parametres />} />
    </Route>
    <Route path="secure" element={<Secure />} />
    <Route path="setup2fa" element={<Setup2fa />} />
</Route>
)
)
root.render(
  <React.StrictMode>
    <Provider store={store} >
    <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
